import React, { useEffect, useState } from "react";
import { Buffer } from 'buffer';
import { useParams } from "react-router-dom";
import axios from "axios";
import Loading from "./components/loading";
// -----------------
// Bandeau déroulant
// -----------------
// import Bandeau from "./components/bandeau"
import "./App.css";
import "./index.css";
import zlib from "react-zlib-js";
import base64url from "base64url";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faAt } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import quipoFigure from "../src/assets/img/QuipoFigure.png";

const App = () => {
  const { tsid } = useParams();
  const [storeData, setStoreData] = useState(null);
  const currentYear = new Date().getFullYear();
  const baseUrlQuipo = "https://app.quipo-app.com/mobileapp?";
  const [ticketZero, setTicketZero] = useState("");
  const [gameUrl, setGameUrl] = useState("");

  const compressData = (data, callBack)  => {
    zlib.deflate(
      Buffer.from(data),
      { level: zlib.Z_BEST_COMPRESSION },
      (errors, dataDecoded) => {
        if (errors) {
          callBack("", errors);
        } else {
          callBack(dataDecoded, errors);
        }
      }
    );
  };

  useEffect(() => {
    if (tsid) {
      axios
        .get(`https://tl-cdn.occiback.dev/v2/store/${tsid}.json`)
        .then((response) => {
          setStoreData(response.data);
          const dataZero = {
            tsid: response.data.tsid,
            storeName: response.data.title,
            storeAddress: response.data.addressAddress,
            v: "1000002208000000000L",
          };
          compressData(JSON.stringify(dataZero), (data, error) => {
            if (error) {
              console.log("WRONG DATA TICKET ZERO!");
            } else {
              const dataString = base64url.fromBase64(
                Buffer.from(data).toString("base64")
              );
              setTicketZero(baseUrlQuipo + encodeURI(dataString));
            }
          });
        })
        .catch((error) => {
          console.error(
            "Erreur lors de la récupération des données du magasin :(",
            error
          );
        });
      axios.get(`https://tl-cdn.occiback.dev/v2/game/${tsid}.json`).then((response) => {
        const dataGame = {
          id: response.data[0].id,
          gname: response.data[0].gname,
          gdesc: response.data[0].gdesc,
          qrcode: response.data[0].extras.qrcode,
        };
        compressData(JSON.stringify(dataGame), (data, error) => {
          if (error) {
            console.log("WRONG DATA GAME!");
          } else {
            setGameUrl(dataGame.qrcode);
          }
        });
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération des données du jeu :(",
          error
        );
      });
    }
  }, [tsid]);

  if (!storeData) return <Loading />;

  const handleClick = () => {
    if (ticketZero==="")
    {
      console.log("C'EST VIDE");
    } else {
      window.open(ticketZero, "_blank");
    }
  };

  const handleClickGame = () => {
    if (gameUrl==="")
    {
      console.log("L'url du game EST VIDE");
    } else {
      window.open(gameUrl, "_blank");
    }
  };

  return (
    <div className="main-container">
      <div className="shop-page" >
        <div className="shop-page-header" style={{ "--store-background": storeData.themeExtras.store.background, "--store-text": storeData.themeExtras.store.text }}>
          <header>
            <div className="logo-div">
              <img className="shop-logo"
                src={`https://tl-cdn.occiback.dev/res/logo/${
                  storeData.logoMain
                }.${storeData.logoMainFtype.toLowerCase()}`}
                alt="shop-logo"
              />
            </div>
            <p 
            className="address-shop"
            style={{"--store-text": storeData.themeExtras.store.text }}>
              {storeData.addressAddress}
            </p>
          </header>
          <section
            className="shop-page-header-deep"
            style={{"--store-text": storeData.themeExtras.store.text }}
          >
            <h1 className="title-shop">
              {storeData.title.toUpperCase()}
            </h1>
            <p className="invite-shop">
              {" "}
              vous propose d'entrer dans sa communauté QUIPO et de profiter d'une expérience client unique!
            </p>
          </section>
          { gameUrl!=="" ?
          <button onClick={() => handleClickGame()} className="action-button">
            JOUEZ !
          </button> : null}
          <div className="logo-quipo-frame">
            <img src={quipoFigure} alt="logoQuipo" className="logo-quipo"/>
          </div>
          <button onClick={() => handleClick()} className="action-button">
            DEMARRER L'EXPERIENCE
          </button>
        </div>

        <div className="separator-container">
          <div className="separator">
            <div className="separator-point left-point"></div>
            <div className="separator-line"></div>
            <div className="separator-point right-point"></div>
          </div>
        </div>

        <div className="shop-media">
          <a href={storeData.phoneAddress} className="phone-button">
            <FontAwesomeIcon icon={faPhone} />
          </a>
          {storeData.socialNetworks?.facebook ? (
            <a
              href={storeData.socialNetworks.facebook}
              className="otre-button"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
          ) : null}
          {storeData.socialNetworks?.instagram ? (
            <a
              href={storeData.socialNetworks.instagram}
              className="otre-button"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          ) : null}
          {storeData.socialNetworks?.linkedin ? (
            <a
              href={storeData.socialNetworks.linkedin}
              className="otre-button"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          ) : null}
          {storeData.socialNetworks?.website ? (
            <a
              href={storeData.socialNetworks.website}
              className="otre-button"
            >
              <FontAwesomeIcon icon={faAt} />
            </a>
          ) : null}
        </div>

        <div className="separator-container">
          <div className="separator">
            <div className="separator-point left-point"></div>
            <div className="separator-line"></div>
            <div className="separator-point right-point"></div>
          </div>
        </div>

        {/* Bandeau déroulant informatif */}

        {/*<div className="shop-alert" style={{ "--ticket-primary": storeData.themeExtras.ticket.theme.primary}}>
            <Bandeau text={textBandeau.toUpperCase()} />
        </div>

        <div className="separator-container">
          <div className="separator">
            <div className="separator-point left-point"></div>
            <div className="separator-line"></div>
            <div className="separator-point right-point"></div>
          </div>
        </div>*/}

        <div className="shop-info" >
          <p className="quote-text">
            " {storeData.description} "
          </p>
          <p className="copyright">
            &copy; {currentYear} QUIPO. Tous droits réservés.
          </p>
        </div>
      </div>
    </div>
  );
};

export default App;